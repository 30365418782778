import React from 'react';
import { Menu, Layout } from 'antd';
import './Header.css';
import { Row, Col } from 'antd';

const { Header } = Layout;

const PageHeader = () => {
  return (
    <Header className="header" style={{backgroundColor:"white"}}>
      <Row justify="space-between" style={{backgroundColor:"white"}}>
        <Col xs={12} sm={6} md={4} lg={3} xl={8}>
          <div className="logo">
            <img src="https://i.fbcd.co/products/resized/resized-750-500/nd-dn-design-letter-logos-2-6dbb559e3a9f3c983b89f79cb003a935d9b43dda0350dba2d412d331cec789f9.jpg" alt="" width={"80px"} height={"40px"} />
            <h1 className='company' style={{}}>DN Vision Technologies</h1>
          </div>
        </Col>
        <Col xs={12} sm={18} md={20} lg={18} xl={14}style={{backgroundColor:"white"}}>
          <Menu theme="light" mode="horizontal" className="menu" style={{color:"black",backgroundColor:"white"}}>
            <Menu.Item onClick={
                () => {
                    window.location.href = "/home";
                }
            } key="/home">Home</Menu.Item>
             <Menu.Item onClick={
                () => {
                    window.location.href = "/about-us";
                }
            } key="/home">About Us</Menu.Item>
             <Menu.Item onClick={
                () => {
                    window.location.href = "/services";
                }
            } key="/home">Services</Menu.Item>
             <Menu.Item onClick={
                () => {
                    window.location.href = "/contact-us";
                }
            } key="/home">Contact</Menu.Item>
           
          </Menu>
        </Col>
      </Row>
    </Header>
  );
};

export default PageHeader;

