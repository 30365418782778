import React from 'react';
import { Row, Col } from 'antd';
import './Help.css';
import Footer from './Footer';

import Header from './HomePage/Header';

const SpareParts = () => {
    return (
        <div style={{backgroundColor:"whitesmoke"}}>
            <Header />
            <Row style={{ padding: "20px", backgroundColor: "grey" }}>
                <Col span={24}>
                    <h1 style={{ textAlign: "center", fontSize: "32px", margin: 0 }}>Spare Parts</h1>
                </Col>
            </Row>
            <Row>
                <Col xs={24} lg={10}>
                    <div style={{ padding: "20px" }}>
                    
                        <h1 style={{ fontSize: "40px" }}>Genuine Certified Spare Parts</h1>
                        <p style={{ fontSize: "26px", paddingLeft: "20px", paddingRight: "20px" }}>
                        Our high quality, genuine certified spare parts. The high level of reliability and safety of the products. We ensure that our customers obtain the benefits of the latest innovations when using our spare parts.
                        </p>
                    </div>
                </Col>
                <Col xs={24} lg={14} style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLieODyrnZLAo5iBia-X6NIg7ZBifchB6fiw&usqp=CAU" alt="" style={{ maxWidth: "100%", height: "auto" }} />
                </Col>
            </Row>
            <Footer />
        </div>
    );
}

export default SpareParts;