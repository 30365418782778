import React from 'react';
import { Carousel } from 'antd';

const contentStyle = {
  height: '500px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
};

const ImageCarousel = () => (
  <Carousel autoplay style={{ height: "500px", marginBottom: "30px", position: 'relative', backgroundImage: 'url("https://img.freepik.com/free-vector/white-abstract-background-design_23-2148825582.jpg?w=2000&t=st=1703205777~exp=1703206377~hmac=ed12c50f8077ac0c0df8664c5e9ced798820171923c4fe115d216337dedfe734")', backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}>
    <div style={{ position: 'relative !important' }}>
      <h1 style={{ position: 'absolute', top: "100px", left: 20 }}>Laser Cutting</h1>
      <img style={{ width: '100%', height: "500px", objectFit: 'contain' }} src='https://user-images.strikinglycdn.com/res/hrscywv4p/image/upload/c_limit,fl_lossy,h_1000,w_500,f_auto,q_auto/3666652/209744_69079.png'></img>
    </div>
    <div style={{ position: 'relative !important' }}>
      <h1 style={{ position: 'absolute', top: "100px", left: 20 }}>Laser Cutting</h1>
      <img style={{ width: '100%', height: "500px", objectFit: 'contain' }} src='https://www.faistgroup.com/site/assets/files/1659/new_frontiers_of_laser_welding_technology.715x0-is.jpg'></img>
    </div>
    <div style={{ position: 'relative !important' }}>
      <h1 style={{ position: 'absolute', top: "100px", left: 20 }}>Laser Cutting</h1>
      <img style={{ width: '100%', height: "500px", objectFit: 'contain' }} src='https://user-images.strikinglycdn.com/res/hrscywv4p/image/upload/c_limit,fl_lossy,h_1000,w_500,f_auto,q_auto/3666652/209744_69079.png'></img>
    </div>
    <div style={{ position: 'relative !important' }}>
      <h1 style={{ position: 'absolute', top: "100px", left: 20 }}>Laser Cutting</h1>
      <img style={{ width: '100%', height: "500px", objectFit: 'contain' }} src='https://user-images.strikinglycdn.com/res/hrscywv4p/image/upload/c_limit,fl_lossy,h_1000,w_500,f_auto,q_auto/3666652/209744_69079.png'></img>
    </div>
  </Carousel>
);

export default ImageCarousel;