import React from 'react';
import { Row, Col } from 'antd';
import './Help.css';
import Footer from './Footer';

import Header from './HomePage/Header';

const OutOfWarrantyServices = () => {
    return (
        <div style={{backgroundColor:"whitesmoke"}}>
            <Header />
            <Row style={{ padding: "20px", backgroundColor: "grey" }}>
                <Col span={24}>
                    <h1 style={{ textAlign: "center", fontSize: "32px", margin: 0 }}>Out Of Warranty Services</h1>
                </Col>
            </Row>
            <Row>
                <Col xs={24} lg={10}>
                    <div style={{ padding: "20px" }}>
                    
                        <h1 style={{ fontSize: "40px" }}>Excellent Out of Warranty Services</h1>
                        <p style={{ fontSize: "26px", paddingLeft: "20px", paddingRight: "20px" }}>
                        Guarantee for uninterrupted processing, installation guide, maintenance guide. We offer excellent out of warranty maintenance services that would help keep your equipment optimised for performance.
                        </p>
                    </div>
                </Col>
                <Col xs={24} lg={14} style={{ display: "flex", justifyContent: "center", alignItems: "center",padding:"20px"}}>
                    <img src="https://thumbs.dreamstime.com/b/warranty-pressing-button-virtual-screen-35134149.jpg" alt="" style={{ maxWidth: "100%", height: "auto" }} />
                </Col>
            </Row>
            <Footer />
        </div>
    );
}

export default OutOfWarrantyServices;