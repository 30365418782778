import React from 'react';
import { Row, Col } from 'antd';
import './Help.css';
import Footer from './Footer';
import Header from './HomePage/Header';

const LaserConsumables = () => {
    return (
        <div style={{ backgroundColor: "whitesmoke" }}>
            <Header />
            <Row style={{ padding: "20px", backgroundColor: "grey" }}>
                <Col span={24}>
                    <h1 style={{ textAlign: "center", fontSize: "32px", margin: 0 }}>Laser Consumables</h1>
                </Col>
            </Row>
            <Row gutter={[16, 16]} justify="center" style={{ padding: "20px" }}>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <div style={{ marginBottom: "20px" }}>
                        <h1>Laser Consumables</h1>
                    </div>
                    <Row gutter={[16, 16]} className='group'>
                        <Col lg={8} xs={24}>
                            <img src="https://xlitetechnologies.com/images/laser-consumables-1.jpg" alt="" style={{ width: "100%" }} />
                        </Col>
                        <Col  lg={8} xs={24}>
                            <img src="https://xlitetechnologies.com/images/laser-consumables-2.jpg" alt="" style={{ width: "100%" }} />
                        </Col>
                        <Col  lg={8} xs={24}>
                            <img src="https://xlitetechnologies.com/images/laser-consumables-3.jpg" alt="" style={{ width: "100%" }} />
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <h1>Single / Double Nozzles</h1>
                    <img src="https://xlitetechnologies.com/images/single-or-double-nozzles.jpg" alt="" style={{ width: "100%" }} />
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <h1>Protection Windows</h1>
                    <img src="https://xlitetechnologies.com/images/protection-windows.jpg" alt="" style={{ width: "100%" }} />
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <h1>Fiber Laser Ceramic</h1>
                    <img src="https://xlitetechnologies.com/images/fiber-laser-ceramic.jpg" alt="" style={{ width: "100%" }} />
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <h1>Laser Welding Spares</h1>
                    <img src="https://xlitetechnologies.com/images/laser-welding-spares.jpg" alt="" style={{ width: "100%" }} />
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <h1>Sensor Cable</h1>
                    <img src="https://xlitetechnologies.com/images/sensor-cable.jpg" alt="" style={{ width: "100%" }} />
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <h1>Focus & Collimating Lens</h1>
                    <img src="https://xlitetechnologies.com/images/focus-and-collimating-lens.jpg" alt="" style={{ width: "100%" }} />
                </Col>
            </Row>
            <Footer />
        </div>
    );
}

export default LaserConsumables;

