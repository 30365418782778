import React from 'react';
import { Row, Col } from 'antd';
import './Help.css';
import Footer from './Footer';
import Header from './HomePage/Header';

const ContactUs = () => {
    return (
        <div style={{ backgroundColor: "whitesmoke" }}>
            <Header />
            <Row style={{ padding: "20px", backgroundColor: "grey" }}>
                <Col span={24}>
                    <h1 style={{ textAlign: "center", fontSize: "32px", margin: 0 }}>Contact Us</h1>
                </Col>
            </Row>
            <Row justify="center" style={{ alignItems: "center",display:"flex",justifyContent:"center" }}>
                <Col span={24} style={{ textAlign: "center" }}>
                    <h1 style={{ fontSize: "40px" }}>Get in touch with us</h1>
                </Col>
                <Col xs={24} sm={24} md={8} style={{ textAlign: "center", margin: "20px 0", alignSelf: "center" }}>
                    <div style={{ backgroundColor: "red",width:"100px", marginBottom: "10px", display: 'inline-block' }}>
                        <img src="https://xlitetechnologies.com/images/loaction-icon.png" alt="" />
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <h1>Address</h1>
                        <p> First Floor, #09, Hegganahalli Cross Bangalore, 6th Cross,</p>
                        <p>Sanjeevini nagar,Bengaluru,</p>
                        <p>Bangalore Urban, Karnataka – 560091</p>



                    </div>
                </Col>
                <Col xs={24} sm={24} md={8} style={{ textAlign: "center", margin: "20px 0", alignSelf: "center" }}>
                    <div style={{ backgroundColor: "red",width:"100px", marginBottom: "10px", display: 'inline-block' }}>
                        <img src="https://xlitetechnologies.com/images/phone-icon.png" alt="" />
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <h1>Phone Number</h1>
                        <p>+91 7349508894</p>
                        <p>+91 9632429218</p>
                        <p>+91 8618693755</p>
                    
                    </div>
                </Col>
                <Col xs={24} sm={24} md={8}  >
                    <div style={{ backgroundColor: "red",width:"100px", marginBottom: "10px", display: 'inline-block' }}>
                        <img src="https://xlitetechnologies.com/images/email-icon.png" alt="" />
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <h1>Email</h1>
                        <p>dnvisiontechnologies@gmail.com</p>
                    </div>
                </Col>

            </Row>
            <Footer />
        </div>
    );
}

export default ContactUs;
