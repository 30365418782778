 import React from "react";
 import './card.css';


const Card = () => {



    return (
        <div style={{display:"flex", justifyContent:"space-around", flexWrap:"wrap",gap:10}}>

       <div className="CardLayout" >
        <img className="imgSection" src="https://media.istockphoto.com/id/1182469238/photo/cnc-laser-machinery-for-metal-cutting.jpg?s=612x612&w=0&k=20&c=SDnVUcxwpCm83jyJdhf_VSOfuyTu1wuo_UKM392AQAM=" alt="" width={"300px"}  height={"400px"}/>
        <h1 className='cardHeading' >Laser Cutting</h1>
       </div>
       <div  className="CardLayout"  >
        <img className="imgSection"  src="https://www.stainless-structurals.com/wp-content/uploads/2017/05/Laser-fusion-process.jpg" alt="" />
        <h1 className='cardHeading' >Laser Welding</h1>
       </div>
       <div  className="CardLayout" >
        <img className="imgSection"  src="https://jbf.co.in/wp-content/uploads/2020/06/cnc-bending-4-scaled.jpg" alt="" />
        <h1 className='cardHeading'>Bending/Shearing</h1>
       </div>
       <div  className="CardLayout"  >
        <img className="imgSection" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0JkfEd7gizw2ASLFfjS8SuE3GD1vigRZM8NGngj4Z8w&s" alt="" />
        <h1 className='cardHeading'>Taping/Insertions</h1>
       </div>
       <div  className="CardLayout"  >
        <img className="imgSection"  src="https://5.imimg.com/data5/SELLER/Default/2021/6/KW/UC/IH/76911645/fiber-laser-cutting-machine-spares-consumables.jpg" alt="" />
        <h1 className='cardHeading'>Laser Consumables</h1>
       </div>
               </div>
    );
}

export default Card;
