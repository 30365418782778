import './App.css';
import HomePage from './HomePage/HomePage';
import AboutUS from './AboutUs';
import {
  BrowserRouter as Router,
  Route, Routes
} from "react-router-dom";
import LaserCutting from './LaserCutting';
import LaserWelding from './LaserWelding';
import TechSupport from './TechSupport';
import SpareParts from './SpareParts';
import OutOfWarrantyServices from './OutOfWarrantyServices';
import ContactUs from './ContactUs';
import LaserConsumables from './LaserConsumables';
import TapingAndInsertions from './TapingAndInsertions';
import Bending from './Bending';

function App() {
  return (
    <div className="App" style={{ height: "100vh" }}>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/about-us" element={<AboutUS />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/laser-cutting" element={<LaserCutting />} />
          <Route path="/laser-welding" element={<LaserWelding />} />
          <Route path="/tech-support" element={<TechSupport />} />
          <Route path="/spare-parts" element={<SpareParts />} />
          <Route path="/services" element={<SpareParts />} />
          <Route path="/out-of-warranty-services" element={<OutOfWarrantyServices />} />
          <Route path="/laser-consumables" element={<LaserConsumables />} />
          <Route path="/taping-and-insertions" element={<TapingAndInsertions />} />
          <Route path="/Bending" element={<Bending />} />

        </Routes>
      </Router>

    </div>
  );
}

export default App;
