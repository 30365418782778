import React from 'react';
import { Row, Col } from 'antd';
import './Help.css';
import Footer from './Footer';
import Header from './HomePage/Header';

const TechSupport = () => {
    return (
        <div style={{backgroundColor:"whitesmoke"}}>
            <Header />
            <Row style={{ padding: "20px", backgroundColor: "grey" }}>
                <Col span={24}>
                    <h1 style={{ textAlign: "center", fontSize: "32px", margin: 0 }}>Tech Support</h1>
                </Col>
            </Row>
            <Row>
                <Col xs={24} lg={12}>
                    <div style={{ padding: "20px" }}>
                    
                        <h1 style={{ fontSize: "40px" }}>Major Repair Activities</h1>
                        <p style={{ fontSize: "26px", paddingLeft: "20px", paddingRight: "20px" }}>
                            Maintenance activity or work that is carried out and necessary to preserve an asset including building and equipment's. Maintenance work enables continued use and function, above a minimum acceptable level of performance, over its design service life, without unforeseen renewal or major repair activities.
                        </p>
                    </div>
                </Col>
                <Col xs={24} lg={12} style={{ display: "flex", justifyContent: "center", alignItems: "center",padding:"10px" }}>
                    <img src="https://xlitetechnologies.com/images/tech-support-1.jpg" alt="" style={{ maxWidth: "100%", height: "auto" }} />
                </Col>
            </Row>
            <Footer />
        </div>
    );
}

export default TechSupport;
