import React from 'react';
import PageHeader from './Header';

import ImageCarousel from '../carousal/Carousal';
import Card from '../Card';
import BioPage from '../BioPage';
import MaterialPage from '../Material';
import LaserTechnologies from '../LaserTechnologies';
import Help from '../Help';
import Footer from '../Footer';
import CopyRight from '../CopyRight';

const HomePage = () => {
    return (
        <div >
        <PageHeader/>
        <ImageCarousel/>
        <Card/>
        <BioPage/>
        <MaterialPage/>
        <LaserTechnologies/>
        <Help/>
        <Footer/>
        <CopyRight/>
      

        
        </div>
    );
};

export default HomePage;