import React, { useState } from 'react';
import { Menu, Layout } from 'antd';

import { Row, Col, Carousel, Button } from 'antd';




const CopyRight = () => {




    return (
        <div className='helpMainSection'>
            <Row style={{display:"flex",justifyContent:"center",color:"white",backgroundColor:"black"}}>
                <p style={{ fontSize: "20px", color: "white", alignItems: "center" }}>© 2023 DN Vision Technologies. All Rights Reserved.</p>
            </Row>

        </div>
    );
}

export default CopyRight;