import React, { useState } from 'react';
import { Menu, Layout } from 'antd';

import { Row, Col, Carousel, Button } from 'antd';
import './Footer.css';



const Footer = () => {




    return (
        <div className='helpMainSection' style={{  backgroundColor:"#334455"}}>
            <Row style={{ display: "flex", flexDirection: "column", marginRight: "50px" }}>
                <Col style={{ marginBottom: "50px" }}>
                    <h1 style={{ color: "red" }}>Quick Links</h1>
                    <a style={{ margin: "10px",color:"white" }} href="/home">Home</a>|
                    <a style={{ margin: "10px",color:"white" }} href="/about-us">About Us</a>|

                    <a style={{ margin: "10px",color:"white" }} href="/contact-us">Contact Us</a>
 

                </Col>
                <Col>
                    <span><img style={{ width: "50px", height: "50px",marginRight:"10px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ56EJWp41yh1Fra0k_OBPZAbsNdU_sFiTzeQ&usqp=CAU" alt="" /></span>
                    <span><img style={{ width: "50px", height: "50px" ,marginRight:"10px" }} src="https://i.pinimg.com/originals/95/aa/ae/95aaaeb1f58987c09ea041fc090e1c20.jpg" alt="" /></span>
                    <span><img style={{ width: "50px", height: "50px"  ,marginRight:"10px"}} src="https://img.freepik.com/premium-vector/modern-badge-logo-instagram-icon_578229-124.jpg?size=626&ext=jpg" alt="" /></span>
                    <span><img style={{ width: "50px", height: "50px"  ,marginRight:"10px"}} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1xD1ycfeJ0nhrfrmD3oq6UMQB-oKcHYvwBA&usqp=CAU" alt="" /></span>

                </Col>

            </Row>
            <Row>
                <Col style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                    {/* <p style={{fontSize:"20px",color:"black"}}>© 2021 DN Vision Technologies. All Rights Reserved.</p> */}
                    <h1 style={{ color: "red" }}>Products</h1>
                    <a style={{ margin: "10px",color:"white" }} href="/laser-cutting">Laser Cutting</a>
                    <a style={{ margin: "10px",color:"white" }} href="/laser-welding">Laser Welding</a>
                    <a style={{ margin: "10px",color:"white" }} href="/bending">Bending / Shearing</a>
                    <a style={{ margin: "10px",color:"white" }} href="/taping-and-insertions">Tapping / Insertions</a>
                    <a style={{ margin: "10px",color:"white" }} href="/laser-consumables">Laser Consumables</a>
                </Col>
            </Row>
            <Row>
                <Col style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                    {/* <p style={{fontSize:"20px",color:"black"}}>© 2021 DN Vision Technologies. All Rights Reserved.</p> */}
                    <h1 style={{ color: "red" }}>Services</h1>
                    <a style={{ margin: "10px",color:"white" }} href="tech-support">Tech Support</a>
                    <a style={{ margin: "10px",color:"white" }} href="spare-parts">Spare Parts</a>
                    <a style={{ margin: "10px",color:"white" }} href="out-of-warranty-services">Out of Warranty Services</a>

                </Col>
            </Row>
            <Row>
                <Col style={{ display: "flex", flexDirection: "column", alignItems: "flex-start",  }}>
                
                    <h1 style={{ color: "red" }}>Contact Us</h1>
                    <div style={{display:"flex"}}>
                  <img style={{ width: "20px", height: "20px",margin:"10px" }} src="https://cdn-icons-png.flaticon.com/128/684/684908.png" alt="" />
                     <p style={{color:"white"}}>  First Floor, #09, Hegganahalli Cross Bangalore, 6th Cross,Sanjeevini nagar,Bengaluru,Bangalore Urban, Karnataka – 560091
                        </p> 
                        </div>
                        <div style={{display:"flex"}}>
                    <img style={{ width: "20px", height: "20px" ,gap:"10px",margin:"10px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvytOHiMgNnkj_aTM9_--xgA5uDbW6CzNBEw&usqp=CAU" alt="" />
                    <p style={{color:"white"}}> +91  7349508894
                        </p> 
                        </div>
                        <div style={{display:"flex"}}>
                    <img style={{ width: "20px", height: "20px",margin:"10px" }} src="https://freepngimg.com/download/gmail/62630-icon-icons-format-computer-save-email-gmail.png" alt="" />
                    <p style={{color:"white"}}>  dnvisiontechnologies.gmail.com
                        </p> 
                        </div>
                   
                </Col>
            </Row>


        </div>
    );
}

export default Footer;