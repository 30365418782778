import React from 'react';
import { Row, Col } from 'antd';
import './laserTechnologies.css';

const LaserTechnologies = () => {
    return (
        <div className='technologies'>
            <h1 className='title'>Where Laser Technologies Could Help You?</h1>
            <Row gutter={[16, 16]} justify='space-around' style={{overflow:"scroll"}}>
                {/* First Section */}
                <Col xs={24} lg={12} xl={8}>
                    <Row gutter={[16, 16]} className='content-section'  >
                        <Col span={24} style={{position:"absolute",}}>
                            <img src="https://xlitetechnologies.com/images/uses-1.jpg" alt="Sheet Metal Cutting Machine" className='image' width={"400px"} height={"400px"} />
                        </Col>
                        <Col span={24} style={{position:"relative",color:"white"}}>
                        <h1 className='content-heading'>Sheet Metal Cutting Machine</h1>
                            <p className='content-para'>The fibre lasers achieve a high level of absorption in metallic materials as they have an electrical-optical efficiency of approx.</p>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} lg={12} xl={8}>
                    <Row gutter={[16, 16]} className='content-section'>
                        <Col span={24} style={{position:"absolute"}}>
                            <img src="https://xlitetechnologies.com/images/uses-2.jpg" alt="Sheet Metal Cutting Machine" className='image' width={"400px"} height={"400px"} />
                        </Col>
                        <Col span={24} style={{position:"relative",color:"white"}}>
                        <h1 className='content-heading'>Sheet & Tube Cutting Machine</h1>
                            <p className='content-para'>The fibre lasers achieve a high level of absorption in metallic materials as they have an electrical-optical efficiency of approx.</p>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} lg={12} xl={8}>
                    <Row gutter={[16, 16]} className='content-section'>
                        <Col span={24} style={{position:"absolute"}}>
                            <img src="https://xlitetechnologies.com/images/uses-3.jpg" alt="Sheet Metal Cutting Machine" className='image' width={"400px"} height={"400px"} />
                        </Col>
                        <Col span={24} style={{position:"relative",color:"white"}}>
                        <h1 className='content-heading'>Tube Metal Cutting Machine</h1>
                            <p className='content-para'>The fibre lasers achieve a high level of absorption in metallic materials as they have an electrical-optical efficiency of approx.</p>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} lg={12} xl={8}>
                    <Row gutter={[16, 16]} className='content-section'>
                        <Col span={24} style={{position:"absolute"}}>
                            <img src="https://xlitetechnologies.com/images/uses-4.jpg" alt="Sheet Metal Cutting Machine" className='image' width={"400px"} height={"400px"} />
                        </Col>
                        <Col span={24} style={{position:"relative",color:"white"}}>
                        <h1 className='content-heading'>Electrolamination</h1>
                            <p className='content-para'>The fibre lasers achieve a high level of absorption in metallic materials as they have an electrical-optical efficiency of approx.</p>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} lg={12} xl={8}>
                    <Row gutter={[16, 16]} className='content-section'>
                        <Col span={24} style={{position:"absolute"}}>
                            <img src="https://xlitetechnologies.com/images/uses-8.jpg" alt="Sheet Metal Cutting Machine" className='image' width={"400px"} height={"400px"} />
                        </Col>
                        <Col span={24} style={{position:"relative",color:"white"}}>
                            <h1 className='content-heading'>Laser Cladding</h1>
                            <p className='content-para'>The fibre lasers achieve a high level of absorption in metallic materials as they have an electrical-optical efficiency of approx.</p>
                        </Col>
                    </Row>
                </Col>
       
            </Row>

        </div>
    );
};

export default LaserTechnologies;
