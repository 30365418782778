import React from 'react';
import { Row, Col } from 'antd';
import './Material.css';

const MaterialPage = () => {
    return (
        <div className='mainSection'>
            <Row style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
                <h1 className='materialHeading' >What would you like to laser?</h1>
                <p  className='para'>Choose your material.</p>
            </Row>
            <Row gutter={[16, 16]} justify='center'>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <div className='materialSection' style={{ backgroundImage: "url('https://eitrawmaterials.eu/wp-content/uploads/2020/07/Endureit-stainless-steel.png')" }}>
                        <h1 className='textContent'>Stainless Steel</h1>
                    </div>

                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <div className='materialSection' style={{ backgroundImage: "url('https://qph.cf2.quoracdn.net/main-qimg-46452d7f3b0f72cbd757f116248a65df-lq')" }}>
                        <h1 className='textContent'>Copper</h1>
                    </div>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <div className='materialSection' style={{ backgroundImage: "url('https://waykenrm.com/wp-content/uploads/2022/02/mild-steel.jpg')" }}>
                        <h1 className='textContent'>Mild Steel</h1>
                    </div>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <div className='materialSection' style={{ backgroundImage: "url('https://millenniumalloys.ca/wp-content/uploads/2020/02/uses-of-aluminum-1024x683.jpeg')" }}>
                        <h1 className='textContent'>Alluminium</h1>
                    </div>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <div className='materialSection' style={{ backgroundImage: "url('https://5.imimg.com/data5/SELLER/Default/2020/10/NI/YR/IW/3471501/acrylic-sheets.jpg')" }}>
                        <h1 className='textContent'>Arcylic</h1>
                    </div>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <div className='materialSection' style={{ backgroundImage: "url('https://5.imimg.com/data5/GF/ZH/HJ/SELLER-86062865/art-paper.jpg')" }}>
                        <h1 className='textContent'>Paper</h1>
                    </div>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <div className='materialSection' style={{ backgroundImage: "url('https://5.imimg.com/data5/XB/SD/DE/SELLER-67059496/soft-wood-500x500.jpg')" }}>
                        <h1 className='textContent'>Wood</h1>
                    </div>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <div className='materialSection' style={{ backgroundImage: "url('https://5.imimg.com/data5/ANDROID/Default/2022/7/PR/MD/OK/29707017/product-jpeg.jpg')" }}>
                        <h1 className='textContent'>MDF</h1>
                    </div>
                </Col>
             
                <Col xs={24} sm={12} md={8} lg={6}>
                    <div className='materialSection' style={{ backgroundImage: "url('https://www.deco.fr/sites/default/files/styles/term_970x336/public/2020-10/shutterstock_617507069.jpg?itok=tn6__MDv')" }}>
                        <h1 className='textContent'>Textile</h1>
                    </div>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <div className='materialSection' style={{ backgroundImage: "url('https://media.istockphoto.com/id/1296032714/photo/plastic-pollution.jpg?s=612x612&w=0&k=20&c=zMR1oY26QuxuS9mk2YlWSvKt8YzUn5z8rG4mLRqjtjE=')" }}>
                        <h1 className='textContent'>Plastic</h1>
                    </div>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <div className='materialSection' style={{ backgroundImage: "url('https://cdn.shopify.com/s/files/1/1150/2764/files/What-is-Leather_1024x1024.jpg?v=1655095031)" }}>
                        <h1 className='textContent'>Leather</h1>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default MaterialPage;

