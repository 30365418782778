import React from 'react';
import { Row, Col } from 'antd';
import './Help.css';
import Footer from './Footer';

import Header from './HomePage/Header';

const AboutUs = () => {
    return (
        <div style={{backgroundColor:"whitesmoke"}}>
            <Header />
            <Row style={{ padding: "20px", backgroundColor: "grey" }}>
                <Col span={24}>
                    <h1 style={{ textAlign: "center", fontSize: "32px", margin: 0 }}>About Us</h1>
                </Col>
            </Row>
            <Row>
                <Col xs={24} lg={10}>
                    <div style={{ padding: "20px" }}>
                    
                        <h1 style={{ fontSize: "40px" }}>We Provide Innovation & Service</h1>
                        <p style={{ fontSize: "26px", paddingLeft: "20px", paddingRight: "20px" }}>
                        The equipment processes products with high precision, smooth cutting, fast speed and higher productivity. We provide innovation and service and with a responsible attitude and professional skills to provide customers with continuous high value service. Laser cutting is a fast, accurate metal solution for your product. CNC controlled for precision. Highly skilled engineers and technicians control the process fast and efficient. Our latest laser cutting technology allows us to provide our customers with the highest quality of manufacturing processes.
                        </p>
                    </div>
                </Col>
                <Col xs={24} lg={14} style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <img src="https://xlitetechnologies.com/images/about-us-1.jpg" alt="" style={{ maxWidth: "100%", height: "auto" }} />
                </Col>
            </Row>
            <Footer />
        </div>
    );
}

export default AboutUs;