import React, { useState } from 'react';
import { Menu, Layout } from 'antd';

import { Row, Col, Carousel,Button } from 'antd';
import './Help.css';



const Help = () => {




    return (
        <div className='helpMainSection' style={{backgroundColor:"whitesmoke"}}>
        <Row style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center", marginTop:"0px",backgroundColor:"whitesmoke"}}>
               <h1 style={{fontSize:"40px"}}>How can we help?</h1>
              <Button className='help' style={{width:"120px",height:"40px",border:"5px solid red",color:"black"}}>Contact us</Button>
              </Row>
            
        </div>
    );
}

export default Help;