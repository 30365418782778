import React from 'react';
import { Row, Col } from 'antd';
import './Help.css';
import Footer from './Footer';
import Header from './HomePage/Header';

const LaserCutting = () => {
    return (
        <div style={{ backgroundColor: "whitesmoke" }}>
            <Header />
            <Row style={{ padding: "20px", backgroundColor: "grey" }}>
                <Col span={24}>
                    <h1 style={{ textAlign: "center", fontSize: "32px", margin: 0 }}>Laser Cutting</h1>
                </Col>
            </Row>
            <Row gutter={[16, 16]} justify="center" style={{ padding: "20px" }}>
                <Col xs={24} lg={10}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <h1 style={{ fontSize: "40px", padding: "20px 0" }}>Ultra High Power</h1>
                        </Col>
                        <Col xs={24} md={12}>
                            <div>
                                <img src="https://xlitetechnologies.com/images/ultra-power-1.jpg" alt="" style={{ width: "100%" }} />
                            </div>
                            <div>
                                <h1>GP Series</h1>
                                <p>Dual platform / High power</p>
                                <p>6000W-40000W</p>
                            </div>
                        </Col>
                        <Col xs={24} md={12}>
                            <div>
                                <img src="https://xlitetechnologies.com/images/ultra-power-2.jpg" alt="" style={{ width: "100%" }} />
                            </div>
                            <div>
                                <h1>L Series</h1>
                                <p>Dual platform / High power</p>
                                <p>6000W-40000W</p>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <h1 style={{ fontSize: "40px", padding: "20px 0" }}>Sheet Metal</h1>
                        </Col>
                        <Col xs={24} md={8}>
                            <div>
                                <img src="https://xlitetechnologies.com/images/sheet-metal-1.jpg" alt="" style={{ width: "100%" }} />
                            </div>
                            <div>
                                <h1>G Series</h1>
                                <p>Dual platform / Sheet metal</p>
                                <p>1500W-6000W</p>
                            </div>
                        </Col>
                        <Col xs={24} md={8}>
                            <div>
                                <img src="https://xlitetechnologies.com/images/sheet-metal-2.jpg" alt="" style={{ width: "100%" }} />
                            </div>
                            <div>
                                <h1>A Series</h1>
                                <p>Precise platform / Sheet metal</p>
                                <p>1000W-4000W</p>
                            </div>
                        </Col>
                        <Col xs={24} md={8}>
                            <div>
                                <img src="https://xlitetechnologies.com/images/sheet-metal-3.jpg" alt="" style={{ width: "100%" }} />
                            </div>
                            <div>
                                <h1>A+ Series</h1>
                                <p>Precise platform / Sheet Metal</p>
                                <p>1000W-3000W</p>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Footer />
        </div>
    );
}

export default LaserCutting;