import React from 'react';
import { Row, Col } from 'antd';
import './Help.css';
import Footer from './Footer';
import Header from './HomePage/Header';

const TapingAndInsertions = () => {
    return (
        <div style={{ backgroundColor: "whitesmoke" }}>
            <Header />
            <Row style={{ padding: "20px", backgroundColor: "grey" }}>
                <Col span={24}>
                    <h1 style={{ textAlign: "center", fontSize: "32px", margin: 0 }}>Taping / Insertions</h1>
                </Col>
            </Row>
            <Row gutter={[16, 16]} justify="center" style={{ padding: "20px" }}>
                <Col xs={24} lg={10}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <h1 style={{ fontSize: "40px", padding: "20px 0" }}>Tapping Machine</h1>
                        </Col>
                        <Col xs={24} md={12}>
                            <div>
                                <img src="https://xlitetechnologies.com/images/electric-tapping-machine-1.jpg" alt="" style={{ width: "100%" }} />
                            </div>
                            <div>
                                <h1>Pneumatic Tapping Machine</h1>
                                <p>Working Stroke 1100 mm</p>
                                <p>Voltage 380V</p>
                            </div>
                        </Col>
                        <Col xs={24} md={12}>
                            <div>
                                <img src="https://xlitetechnologies.com/images/electric-tapping-machine-2.jpg" alt="" style={{ width: "100%" }} />
                            </div>
                            <div>
                                <h1>Electric Tapping Machine</h1>
                                <p>Working Stroke 1700 mm</p>
                                <p>Voltage 380V</p>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <h1 style={{ fontSize: "40px", padding: "20px 0" }}>Tapping Machine</h1>
                        </Col>
                        <Col xs={24} md={24}>
                            <div>
                                <img src="https://xlitetechnologies.com/images/tapping-machine.jpg" alt="" style={{ width: "100%" }} />
                            </div>
                            <div>
                                <h1>PEM Nut Insertion Machine</h1>
                                <p>Force 54KN</p>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Footer />
        </div>
    );
}

export default TapingAndInsertions;
