import React from 'react';
import { Row, Col } from 'antd';
import './Bio.css';

const BioPage = () => {
    return (
        <div className='mainSection'>
            <Row justify="center" align="middle">
                <Col xs={22} sm={20} md={16} lg={14} xl={18}>
                    <h1>About DN Vision Technologies</h1>
                    <p className='para'>The equipment processes products with high precision, smooth cutting, fast speed, and higher productivity. We provide innovation and service with a responsible attitude and professional skills to offer customers continuous high-value service. Laser cutting is a fast, accurate metal solution for your product. CNC controlled for precision. Highly skilled engineers and technicians control the process fast and efficiently. Our latest laser cutting technology allows us to provide our customers with the highest quality manufacturing processes.</p>
                    <button className='circle-btn'>Read More</button>
                </Col>
            </Row>
        </div>
    );
}

export default BioPage;

